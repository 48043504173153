import { Spinner, SpinnerSize } from "@fluentui/react";
import { History } from "history";
import { logService } from "npo-common";
import * as React from "react";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Dispatch } from "redux";
import LocalePicker from "src/components/LocalePicker";
import SignInComponent from "src/components/SignIn";
import SignOutComponent from "src/components/SignOut";
import SignupCompletePage from "src/pages/signup-complete/SignupCompletePage";
import WithContactUs from "../../components/WithContactUs";
import WithProfile from "../../components/WithProfile";
import AgentVerification from "../../components/agent-verification/AgentVerification";
import { AuthAutoRenew } from "../../components/app/AuthAutoRenew";
import { AuthRequiredBoundary, HasErrorBoundary, HasIncompleteProfile } from "../../components/boundaries/Boundary";
import WcpCookieConsent from "../../components/cookie-consent/WcpCookieConsent";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import ConfirmationModal from "../../components/profilev2/ConfirmationModal";
import Flag from "../../components/utilities/Flag/Flag";
import { fetchLanguageDetail, getLocaleSettings } from "../../components/utilities/Localization/Utils";
import Refocus from "../../components/utilities/Refocus/Refocus";
import localeService from "../../dataServices/LocaleService";
import WithNonprofit from "../../pages/WithNonprofit";
import Wrapper from "../../pages/Wrapper";
import ContactUsPageV2 from "../../pages/contactus/ContactUsPageV2";
import { EligibilityChangesPage } from "../../pages/eligbility/EligibilityChangesPage";
import ErrorPage from "../../pages/error/ErrorPage";
import GettingStartedV2 from "../../pages/getting-startedV2/GettingStartedV2";
import { HomePageV2 } from "../../pages/home/HomePageV2";
import { GettingStartedPage as GettingStartedPageNew } from "../../pages/home/new/GettingStartedPage";
import OfferPage from "../../pages/offers/OfferPage";
import ProfilePageV2 from "../../pages/profileV2/ProfilePageV2";
import { RegistrationCompletePage } from "../../pages/registration-complete/RegistrationCompletePage";
import { IApplicationState } from "../../state";
import { AuthState, initiateLogin, initiateLogout } from "../../state/auth";
import { IFeatureFlagsState } from "../../state/feature-flags";
import { localizationRequest } from "../../state/localization/actions";
import { ILocalizationState, LocaleDetail } from "../../state/localization/types";
import { extractLocale, isNullOrUndefined } from "../utilities/Utilities";
import HomeRedirect from "./HomeRedirect";

interface IPropsFromState {
  pathname: string;
  isProfileCreation: boolean;
  isCommercialIncomplete: boolean;
  featureFlags: IFeatureFlagsState;
  localizationState: ILocalizationState;
  authState: AuthState;
}

interface IPropsFromDispatch {
  initiateLogin: typeof initiateLogin;
  initiateLogout: typeof initiateLogout;
  initiateLocalization: typeof localizationRequest;
}

interface IOwnProps {
  history: History;
}

export type IRoutesProps = IPropsFromState & IOwnProps & IPropsFromDispatch;
export class LocalizedRouter extends React.Component<IRoutesProps> {
  private localizationSettingForUrl: LocaleDetail;
  private header = document.getElementById('uhfHeader');
  private footer = document.getElementById('uhfFooter');
  gettingStartedV2SupportedRegions: string[];

  constructor(props: IRoutesProps) {
    super(props);

    // Fetches the localization setting for the locale from Url.
    this.localizationSettingForUrl = this.fetchLocalizationSettingForUrl(this.props.pathname);
    const sessionStorageLocale = sessionStorage.getItem("locale");
    const localeValue = this.evaluateLocale(this.localizationSettingForUrl, sessionStorageLocale ?? undefined);
    const contactusSupportRegions = process.env.REACT_APP_CONTACTUS_SUPPORTED_REGIONS?.split(",");
    const offersSupportRegions = process.env.REACT_APP_OFFERS_SUPPORTED_REGIONS?.split(",");
    const currentLocaleForUrl = this.getLocaleFromUrl(this.localizationSettingForUrl.currentPath);
    const urlWithoutLocale = this.localizationSettingForUrl.currentPath?.replace("/" + currentLocaleForUrl, "");
    this.gettingStartedV2SupportedRegions = process.env.REACT_APP_GETTING_STARTED_V2_SUPPORTED_REGIONS?.split(",") ?? [];

    window.addEventListener('UHFEndpointFailed', (event: any) => {
      logService.logError(event.detail);
    });

    if (!this.localizationSettingForUrl.isValidForRoute) {
      window.location.replace(
        window.location.origin +
          "/" +
          this.props.localizationState.defaultAppLanguage.toLowerCase() +
          "/" +
          this.localizationSettingForUrl.currentPath
      );
      this.props.initiateLocalization(this.props.localizationState.defaultAppLanguage.toLowerCase());
    } else if (
      localeValue !== undefined &&
      this.localizationSettingForUrl.currentPath?.toLowerCase().endsWith("/contactus") &&
      !contactusSupportRegions?.some(contactusSupportRegion =>
        this.localizationSettingForUrl.currentPath?.toLowerCase().startsWith(contactusSupportRegion)
      )
    ) {
      window.location.replace(
        window.location.origin + "/" + this.props.localizationState.defaultAppLanguage.toLowerCase() + "/contactus"
      );
      this.props.initiateLocalization(this.props.localizationState.defaultAppLanguage.toLowerCase());
    } else if (
      localeValue !== undefined &&
      urlWithoutLocale?.toLowerCase().startsWith("/offers") &&
      !offersSupportRegions?.some(offersSupportRegion =>
        this.localizationSettingForUrl.currentPath?.toLowerCase().startsWith(offersSupportRegion)
      )
    ) {
      window.location.replace(
        window.location.origin + "/" + this.props.localizationState.defaultAppLanguage.toLowerCase() + urlWithoutLocale
      );
      this.props.initiateLocalization(this.props.localizationState.defaultAppLanguage.toLowerCase());
    } else if (localeValue !== undefined) {
      this.props.initiateLocalization(localeValue);
    } else {
      this.props.initiateLocalization();
    }
  }

  public render() {
    if (
      this.props.localizationState.currentLocale !== undefined &&
      this.props.localizationState.isLoading === false &&
      this.props.localizationState.isSuccessfulRetrival === true &&
      this.props.authState.isLoading === false
    ) {
      const oldPathMessage = localeService.getText("Shared", "Redirect");
      const locale = this.props.localizationState.currentLocale;
      const showOldNonprofitHeaderFooter = !(
        this.props.featureFlags.UHFEnabled &&
        this.gettingStartedV2SupportedRegions.includes(locale) &&
        (window.location.pathname.includes('getting-started') ||
          window.location.pathname.includes('locale'))
      );
      const isMeControlPage = window.location.pathname.includes('mecontrol');

      // hide uhf in document based on flag and location
      if (this.header && showOldNonprofitHeaderFooter) {
        this.header.hidden = true;
      } else if (this.header) {
        this.header.hidden = false;
      }

      if (this.footer && showOldNonprofitHeaderFooter) {
        this.footer.hidden = true;
      } else if (this.footer) {
        this.footer.hidden = false;
      }

      return (
        <div id="container">
          {/* <Locale /> */}
          <Refocus />
          <WcpCookieConsent />
          <AgentVerification />
          {showOldNonprofitHeaderFooter && !isMeControlPage && <Header />}
          <main id="content" className="p-0 m-0">
              <DocumentTitle title={this.generateTitle()}>
                <Wrapper>
                  <Switch>
                    {
                      // Redirect to /locale/pathanme, if locale is not present in pathname
                      // Ex: Redirect /getting-started to /en-us/getting-started
                      // Default redirect was causing issue where featureFlags.isLoading was false incorrectly on redirect
                      !this.props.pathname.toLowerCase().includes(`/${locale.toLowerCase()}`)
                      && <HomeRedirect locale={locale.toLocaleLowerCase()} pathname={this.props.pathname} />
                    }
                    <Route
                      exact
                      path={`/${locale}`}
                      render={() => (
                        <AuthRequiredBoundary redirect={`/${locale}/getting-started`}>
                          <WithNonprofit>
                            <HasIncompleteProfile redirect={this.props.isCommercialIncomplete ? `/${locale}/new/profile/incomplete` : `/${locale}/profile`}>
                              <HomePageV2 />
                            </HasIncompleteProfile>
                          </WithNonprofit>
                        </AuthRequiredBoundary>
                      )}
                    />

                    <Route
                      exact
                      path={`/${locale}/getting-started`}
                      render={props =>
                        this.props.featureFlags.isLoading ? (
                          <Spinner
                            size={SpinnerSize.large}
                            label={localeService.getText("Shared", "SpinnerPleaseWait")}
                            ariaLive="assertive"
                          />
                        ) : this.gettingStartedV2SupportedRegions.includes(locale) ? (
                          <GettingStartedV2 />
                        ) : (
                          <GettingStartedPageNew />
                        )
                      }
                    />

                    <Route
                      path={`/${locale}/mecontrol/signin`}
                      render={() => <SignInComponent />}
                    />

                    <Route
                      path={`/${locale}/mecontrol/signout`}
                      render={() => <SignOutComponent />}
                    />

                    <Route
                      exact
                      path={`/${locale}/signin`}
                      render={() => <Redirect to={`/${locale}/getting-started`} />}
                    />


                    <Route
                      exact
                      path={`/${locale}/signup-complete`}
                      render={props => {
                        return <SignupCompletePage />
                      }}
                    />

			              <Route
                      exact
                      path={`/${locale}/convert-account`}
                      render={props => {
                        return <SignupCompletePage />
                      }}
                    />

                    <Route
                      exact
                      path={`/${locale}/locale`}
                      render={props => {
                        return <LocalePicker supportedRegions={this.gettingStartedV2SupportedRegions} locale={locale} />
                      }}
                    />

                    <Route
                      exact
                      path={`/${locale}/register`}
                      render={() => <Redirect to={`/${locale}/getting-started`} />}
                    />

                    <Route
                      exact
                      path={`/${locale}/contactus/:category/:subCategory`}
                      render={props =>
                        this.props.featureFlags.isLoading ? (
                          <Spinner
                            size={SpinnerSize.large}
                            label={localeService.getText("Shared", "SpinnerPleaseWait")}
                            ariaLive="assertive"
                          />
                        ) : (
                          <WithContactUs>
                            <ContactUsPageV2 {...props} />
                          </WithContactUs>
                        )
                      }
                    />

                    <Route
                      exact
                      path={`/${locale}/contactus`}
                      render={props =>
                        this.props.featureFlags.isLoading ? (
                          <Spinner
                            size={SpinnerSize.large}
                            label={localeService.getText("Shared", "SpinnerPleaseWait")}
                            ariaLive="assertive"
                          />
                        ) : (
                          <WithContactUs>
                            <ContactUsPageV2 {...props} />
                          </WithContactUs>
                        )
                      }
                    />

                    <Route
                      exact
                      path={`/${locale}/offers/:id/:scrollTo?`}
                      render={props => (
                        <AuthRequiredBoundary redirect={`/${locale}/getting-started`}>
                          <WithNonprofit>
                            <HasIncompleteProfile redirect={this.props.isCommercialIncomplete ? `/${locale}/new/profile/incomplete` : `/${locale}/profile`}>
                              <OfferPage {...props as any} />
                            </HasIncompleteProfile>
                          </WithNonprofit>
                        </AuthRequiredBoundary>
                      )}
                    />

                    <Route
                      exact
                      path={`/${locale}/registration-complete`}
                      render={() => (
                        <AuthRequiredBoundary redirect={`/${locale}`}>
                          <WithNonprofit>
                            <RegistrationCompletePage />
                          </WithNonprofit>
                        </AuthRequiredBoundary>
                      )}
                    />

                    <Route path={`/${locale}/azurerenew`} render={() => <Redirect to={`/${locale}/offers/azure`} />} />

                    <Route
                      exact
                      path={`/${locale}/profile`}
                      render={props => (
                        <AuthRequiredBoundary redirect={`/${locale}`}>
                          <WithNonprofit>
                            <WithProfile>
                              <ProfilePageV2 />
                            </WithProfile>
                          </WithNonprofit>
                        </AuthRequiredBoundary>
                      )}
                    />

                    <Route
                      exact
                      path={`/${locale}/new/profile/incomplete`}
                      render={props => (
                        <AuthRequiredBoundary redirect={`/${locale}`}>
                          <WithNonprofit>
                            <WithProfile>
                              <ConfirmationModal
                                showConfirmation={true}
                                revalidationRequired={false}
                                convertAccount={true}
                              />
                            </WithProfile>
                          </WithNonprofit>
                        </AuthRequiredBoundary>
                      )}
                    />

                    <Route
                      exact
                      path={`/${locale}/new/profile`}
                      render={props => (
                        <AuthRequiredBoundary redirect={`/${locale}`}>
                          <WithNonprofit>
                            <WithProfile>
                              <ProfilePageV2 />
                            </WithProfile>
                          </WithNonprofit>
                        </AuthRequiredBoundary>
                      )}
                    />

                    <Route
                      exact
                      path={`/${locale}/info/eligibilitychanges`}
                      render={() => <EligibilityChangesPage />}
                    />

                    <Route
                      exact
                      path={`/${locale}/error/:code`}
                      render={props => (
                        <HasErrorBoundary redirect={`/${locale}`}>
                          <ErrorPage {...props as any} />
                        </HasErrorBoundary>
                      )}
                    />

                    {/* BEGIN Old portal redirects */}
                    <Route exact path="/applicationstatus" render={() => <p>{oldPathMessage}</p>} />
                    <Route exact path="/reseller" render={() => <p>{oldPathMessage}</p>} />
                    <Route exact path="/ngoportal" render={() => <p>{oldPathMessage}</p>} />
                    {/* END Old portal redirects */}

                    <Route path="/:path" render={() => <Redirect to={`/${locale}`} />} />
                    <Route component={() => <div>{localeService.getText("NotFoundPage", "NotFound")}</div>} />
                  </Switch>
                </Wrapper>
              </DocumentTitle>
          </main>
          {showOldNonprofitHeaderFooter && !isMeControlPage && <Footer />}
          <AuthAutoRenew authState={this.props.authState} initiateLogout={this.props.initiateLogout} />
        </div>
      );
    } else {
      return (
        <Spinner
          size={SpinnerSize.large}
          label={localeService.getText("Shared", "SpinnerPleaseWait")}
          ariaLive="assertive"
        />
      );
    }
  }

  private generateTitle(): string {
    const { pathname } = this.props;
    const baseTitle = localeService.getText("DocumentTitles", "/");
    const pathnameNoLocale =
      "/" +
      pathname
        .split("/")
        .splice(2)
        .join("/");
    let pathTitle = pathname !== "/" ? localeService.getText("DocumentTitles", pathnameNoLocale) : null;
    return pathTitle ?? baseTitle ?? '';
  }

  /**
   * Fetches localization setting for a given path with or without localization parameter.
   * @param path path to be rendered.
   */
  private fetchLocalizationSettingForUrl(path: string) {
    const localeFromUrl = extractLocale(path);
    const validLocaleSetting = getLocaleSettings(localeFromUrl);
    const localeLowerCase = !isNullOrUndefined(validLocaleSetting)
      ? validLocaleSetting?.locale.toLowerCase()
      : undefined;
    const routeDetailForLanguage = fetchLanguageDetail(path, localeLowerCase);

    return {
      locale: localeLowerCase,
      isValidForRoute: routeDetailForLanguage.isValidForRoute,
      currentPath: routeDetailForLanguage.currentRoute
    };
  }

  private evaluateLocale(localeFromURL: LocaleDetail, sessionStorageLocale: string | undefined) {
    if (!localeFromURL.isValidForRoute) {
      return this.props.localizationState.defaultAppLanguage.toLowerCase();
    }

    if (
      sessionStorageLocale !== undefined &&
      localeFromURL.locale !== undefined &&
      sessionStorageLocale !== localeFromURL.locale
    ) {
      // this is the only case where we have to return localeFromURL.
      return localeFromURL.locale;
    }

    return sessionStorageLocale;
  }

  private getLocaleFromUrl(path: string | undefined) {
    const urlComponents = path?.split("/");
    return urlComponents?.[1];
  }
}

const mapStateToProps = ({ profile, nonprofit, flags, locale, auth }: IApplicationState): IPropsFromState => ({
  pathname: window.location.pathname,
  isProfileCreation: profile.isNewProfile || nonprofit.isCommercial || !profile.profile,
  isCommercialIncomplete: nonprofit.miniProfile && nonprofit.miniProfile.isIncomplete && nonprofit.isCommercial,
  featureFlags: flags,
  localizationState: locale,
  authState: auth
});

const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
  initiateLogin: () => dispatch(initiateLogin()),
  initiateLogout: () => dispatch(initiateLogout()),
  initiateLocalization: (value: string | undefined) => dispatch(localizationRequest(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalizedRouter);
