import { Spinner, SpinnerSize } from "@fluentui/react";
import { History } from "history";
import * as React from "react";
import * as DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Dispatch } from "redux";
import SignInComponent from "src/components/SignIn";
import SignOutComponent from "src/components/SignOut";
import GettingStartedV2 from "src/pages/getting-startedV2/GettingStartedV2";
import WithContactUs from "../../components/WithContactUs";
import WithProfile from "../../components/WithProfile";
import AgentVerification from "../../components/agent-verification/AgentVerification";
import { AuthAutoRenew } from "../../components/app/AuthAutoRenew";
import {
  AuthRequiredBoundary, HasErrorBoundary,
  HasIncompleteProfile
} from "../../components/boundaries/Boundary";
import WcpCookieConsent from "../../components/cookie-consent/WcpCookieConsent";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Flag from "../../components/utilities/Flag/Flag";
import { getSupportedLanguages, isLocalizationEnabled } from "../../components/utilities/Localization/Utils";
import Refocus from "../../components/utilities/Refocus/Refocus";
import localeService from "../../dataServices/LocaleService";
import WithNonprofit from "../../pages/WithNonprofit";
import Wrapper from "../../pages/Wrapper";
import ContactUsPageV2 from "../../pages/contactus/ContactUsPageV2";
import { EligibilityChangesPage } from "../../pages/eligbility/EligibilityChangesPage";
import ErrorPage from "../../pages/error/ErrorPage";
import HomePageV2 from "../../pages/home/HomePageV2";
import OfferPage from "../../pages/offers/OfferPage";
import ProfilePageV2 from "../../pages/profileV2/ProfilePageV2";
import { RegistrationCompletePage } from "../../pages/registration-complete/RegistrationCompletePage";
import { IApplicationState } from "../../state";
import { AuthState, initiateLogin, initiateLogout } from "../../state/auth";
import { IFeatureFlagsState } from "../../state/feature-flags";

interface IPropsFromState {
  pathname: string;
  isProfileCreation: boolean;
  featureFlags: IFeatureFlagsState;
  authState: AuthState;
}

interface IPropsFromDispatch {
  initiateLogin: typeof initiateLogin;
  initiateLogout: typeof initiateLogout;
}

interface IOwnProps {
  history: History;
}
export type IRoutesProps = IPropsFromState & IPropsFromDispatch & IOwnProps;
export class Routes extends React.Component<IRoutesProps> {
  private header = document.getElementById('uhfHeader');
  private footer = document.getElementById('uhfFooter');

  public render() {
    
    const oldPathMessage = localeService.getText("Shared", "Redirect");
    const showOldNonprofitHeaderFooter = !(
      this.props.featureFlags.UHFEnabled && window.location.pathname.includes('en-us/getting-started')
    );
    const isMeControlPage = window.location.pathname.includes('mecontrol');

    // hide uhf in document based on flag and location
    if (this.header && showOldNonprofitHeaderFooter) {
      this.header.hidden = true;
    } else if (this.header) {
      this.header.hidden = false;
    }

    if (this.footer && showOldNonprofitHeaderFooter) {
      this.footer.hidden = true;
    } else if (this.footer) {
      this.footer.hidden = false;
    }

    // When localized Url is received when localization feature is turned off.
    if (!isLocalizationEnabled()) {
      const localizedUrl = this.fetchLanguageInRoute(this.props.pathname);
      if (localizedUrl !== undefined) {
        const currrentRoute = this.props.pathname.toLowerCase();
        const newPath = currrentRoute.replace(`${localizedUrl}`, "");

        return <Redirect to={`/${newPath}`} />;
      }
    }

    if (this.props.authState.isLoading === false) {
      return (
        <div id="container">
          {/* <Locale /> */}
          <Refocus />
          <WcpCookieConsent />
          <AgentVerification />
          {showOldNonprofitHeaderFooter && !isMeControlPage && <Header />}
          <main id="content">
            {
              // @ts-ignore: Import error
              <DocumentTitle title={this.generateTitle()}>
                <Wrapper>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => (
                        <AuthRequiredBoundary redirect="/getting-started">
                          <WithNonprofit>
                            <HasIncompleteProfile redirect="/profile">
                              <HomePageV2 />
                            </HasIncompleteProfile>
                          </WithNonprofit>
                        </AuthRequiredBoundary>
                      )}
                    />

                    <Route
                      exact
                      path={`/getting-started`}
                      render={props =>
                        this.props.featureFlags.isLoading ? (
                          <Spinner
                            size={SpinnerSize.large}
                            label={localeService.getText("Shared", "SpinnerPleaseWait")}
                            ariaLive="assertive"
                          />
                        ) :
                          <GettingStartedV2 />
                      }
                    />

                    <Route
                      path={`/mecontrol/signin`}
                      render={() => <SignInComponent />}
                    />

                    <Route
                      path={`/mecontrol/signout`}
                      render={() => <SignOutComponent />}
                    />

                    <Route exact path={`/signin`} render={() => <Redirect to={`/getting-started`} />} />
                    <Route exact path={`/register`} render={() => <Redirect to={`/getting-started`} />} />

                    <Route
                      exact
                      path={`/registration-complete`}
                      render={() => (
                        <AuthRequiredBoundary redirect="/getting-started">
                          <RegistrationCompletePage />
                        </AuthRequiredBoundary>
                      )}
                    />

                    <Route
                      exact
                      path="/contactus/:category/:subCategory"
                      render={props =>
                        this.props.featureFlags.isLoading ? (
                          <Spinner
                            size={SpinnerSize.large}
                            label={localeService.getText("Shared", "SpinnerPleaseWait")}
                            ariaLive="assertive"
                          />
                        ) : (
                          <WithContactUs>
                            <ContactUsPageV2 {...props} />
                          </WithContactUs>
                        )
                      }
                    />

                    <Route
                      exact
                      path="/contactus"
                      render={props =>
                        this.props.featureFlags.isLoading ? (
                          <Spinner
                            size={SpinnerSize.large}
                            label={localeService.getText("Shared", "SpinnerPleaseWait")}
                            ariaLive="assertive"
                          />
                        ) : (
                          <WithContactUs>
                            <ContactUsPageV2 {...props} />
                          </WithContactUs>
                        )
                      }
                    />

                    <Route
                      exact
                      path="/offers/:id/:scrollTo?"
                      render={props => (
                        <AuthRequiredBoundary redirect="/getting-started">
                          <WithNonprofit>
                            <HasIncompleteProfile redirect="/profile">
                              <OfferPage {...props as any} />
                            </HasIncompleteProfile>
                          </WithNonprofit>
                        </AuthRequiredBoundary>
                      )}
                    />

                    <Route path="/azurerenew" render={() => <Redirect to={"/offers/azure"} />} />

                    <Route
                      exact
                      path="/profile"
                      render={props => (
                        <AuthRequiredBoundary redirect="/">
                          <WithNonprofit>
                            <WithProfile>
                              <ProfilePageV2 />
                            </WithProfile>
                          </WithNonprofit>
                        </AuthRequiredBoundary>
                      )}
                    />

                    <Route exact path="/info/eligibilitychanges" render={() => <EligibilityChangesPage />} />

                    <Route
                      exact
                      path="/error/:code"
                      render={props => (
                        <HasErrorBoundary redirect="/">
                          <ErrorPage {...props as any} />
                        </HasErrorBoundary>
                      )}
                    />

                    {/* BEGIN Old portal redirects */}
                    <Route exact path="/applicationstatus" render={() => <p>{oldPathMessage}</p>} />
                    <Route exact path="/reseller" render={() => <p>{oldPathMessage}</p>} />
                    <Route exact path="/ngoportal" render={() => <p>{oldPathMessage}</p>} />
                    {/* END Old portal redirects */}

                    <Route component={() => <div>Not Found.</div>} />
                  </Switch>
                </Wrapper>
              </DocumentTitle>
            }
          </main>
          {showOldNonprofitHeaderFooter && !isMeControlPage && <Footer />}
          <AuthAutoRenew authState={this.props.authState} initiateLogout={this.props.initiateLogout} />
        </div>
      );
    } else {
      return (
        <Spinner
          size={SpinnerSize.large}
          label={localeService.getText("Shared", "SpinnerPleaseWait")}
          ariaLive="assertive"
        />
      );
    }
  }

  private fetchLanguageInRoute(path: string) {
    if (path === null || path === undefined) {
      return undefined;
    }

    // looks for patterns of format "en-us" or "en-US"
    const regexPatternForLocale = "([a-z]{2})-([a-zA-Z]{2})";
    const supportedLanguages: string[] = getSupportedLanguages();

    // find exact language to match
    const results = path.match(regexPatternForLocale);
    if (results && results.length !== 0 && supportedLanguages.includes(results[0].toLowerCase())) {
      // find with slashes once validated
      const regexLocaleWithSlash = "(/)([a-z]{2})-([a-zA-Z]{2})(/?)";
      const resultPath = path.match(regexLocaleWithSlash);

      return resultPath?.[0].toLowerCase();
    }

    return undefined;
  }

  private generateTitle(): string {
    const { pathname } = this.props;
    const baseTitle = localeService.getText("DocumentTitles", "/");

    let pathTitle = pathname !== "/" ? localeService.getText("DocumentTitles", pathname) : null;
    pathTitle = pathTitle ? ` - ${pathTitle}` : "";

    return `${baseTitle}${pathTitle}`;
  }
}

const mapStateToProps = ({ profile, nonprofit, flags, auth }: IApplicationState): IPropsFromState => ({
  pathname: window.location.pathname,
  isProfileCreation: profile.isNewProfile || nonprofit.isCommercial || !profile.profile,
  featureFlags: flags,
  authState: auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiateLogin: () => dispatch(initiateLogin()),
  initiateLogout: () => dispatch(initiateLogout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);
